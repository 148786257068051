//Custom Error Message
.custom-errormessage {
  background-color: var(--ion-color-danger-tint);
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 4px 2px 4px 2px;
  border: 1px solid var(--ion-color-danger-shade);
  border-radius: 8px;
  color: white;

  ion-item {
    --border-radius: 8px;
    --color: white;
    --background: var(--ion-color-danger-tint);
  }
}
